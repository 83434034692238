// カートページ

<template lang="pug">
.order-cart

  h2.text-3xl.font-bold.text-teal-700.lg__text-center 買い物カゴ

  .outer.mx-auto

    p.emtpy.border-2.border-gray-400.border-dashed.mx-auto.mt-4.text-center.p-3(
      v-if='cartIsEmpty'
    )
      | カゴの中身が空です。

    template(v-else)
      p.mt-6 買い物カゴの中身は以下のとおりです。ご注文手続きに進むには「ご注文手続きへ」ボタンを押してください。

      ul
        li.mt-3(v-for='item in cartItems')
          book-info(
            type='cart',
            :name='item.bookName',
            :organization='item.organization',
            :child='item.childName',
            :unit-price='item.unitPrice',
            :quantity='item.quantity',
            :created-at='item.createdAt',
            @updateQuantity='updateQuantity(item.id, $event)',
            @clickPreview='clickPreview(item)',
            @clicRemove='removeItem(item.id)'
          )

      .total.text-right.mt-4
        .inline-block.border-b.border-red-300
          | 商品金額:
          b.ml-2.text-2xl {{ totalQuantity }}
          span.text-sm 点
          b.ml-2.text-2xl.text-red-600 {{ subTotalPrice }}
          span.text-sm.text-red-600 円(税別)

    footer.text-center.mt-20
      router-link.btn.bg-red-500.text-white.p-3(
        v-if='!cartIsEmpty',
        :to='{ name: "OrderForm" }'
      )
        fa-icon(icon='shopping-cart', size='lg')
        span.ml-2 ご注文手続きへ
      br
      router-link.btn.mt-4(
        :to='{ name: "MyHome" }'
      ) マイページへ
</template>

<script>
import { mapActions } from 'vuex'
import get from 'lodash.get'
import range from 'lodash.range'

import getCartApi from '../../api/cart/get'
import updateCartItemQuantityApi from '../../api/cart/update'
import removeCartItemApi from '../../api/cart/remove'
import BookInfo from '../../components/book-info'
import errorCodes from '../../errors/codes'

export default {
  name: 'OrderCart',

  components: {
    BookInfo
  },

  data () {
    return {
      cartDetail: null
    }
  },

  computed: {
    cartItems () {
      return get(this.cartDetail, 'items', [])
    },
    cartIsEmpty () {
      return this.cartItems.length === 0
    },
    subTotalPrice () {
      return get(this.cartDetail, 'subTotal', '---')
    },
    totalQuantity () {
      return get(this.cartDetail, 'totalQuantity', '--')
    },
    quantities () {
      const max = parseInt(process.env.VUE_APP_MAX_QUANTITY_PER_ITEM, 10)
      return range(1, max + 1)
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    ...mapActions({
      setError: 'app/setError'
    }),
    // 初期化
    async init () {
      const response = await getCartApi()
      if (!response.ok) {
        this.setError({ code: errorCodes.GET_CART_ERROR })
        return
      }
      this.cartDetail = response.payload
    },
    // 数量が更新された
    async updateQuantity (cartItemId, quantity) {
      const _quantity = parseInt(quantity, 10)
      const response = await updateCartItemQuantityApi({
        cartItemId,
        quantity: _quantity
      })
      if (!response.ok) {
        this.setError({ code: errorCodes.ADD_ITEM_TO_CART_ERROR })
      }
      // 成功時は金額等更新があるため、カゴ情報を再取得
      // 失敗時も本来の個数に戻すために、再取得
      await this.init()
    },
    // カゴのアイテムを削除
    async removeItem (cartItemId) {
      const response = await removeCartItemApi(cartItemId)
      if (!response.ok) {
        this.setError({ code: errorCodes.REMOVE_CART_ITEM_ERROR })
        // 失敗したときは何もしない
        return
      }
      // 更新
      await this.init()
    },
    clickPreview (item) {
      this.$router.push({
        name: 'EditorPreview',
        params: { bookId: item.bookId }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.order-cart
  .outer
    max-width: 800px
</style>
