// 特定のアイテムを削除する

import api from '../api'

const path = '/cart'

export default cartItemId => {
  return api({
    method: 'delete',
    url: path,
    auth: true,
    params: { cartItemId }
  })
}
