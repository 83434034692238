// 特定のアイテムの数量を変更する

import api from '../api'

const path = '/cart'

export default ({ cartItemId, quantity }) => {
  return api({
    method: 'put',
    url: path,
    auth: true,
    params: { cartItemId, quantity }
  })
}
